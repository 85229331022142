import { Theme } from '@emotion/react'
import { SportName } from '@the-game/api-interfaces'
import {
    ThemeColorOverrideMap,
    cricketColors,
    waflColors,
    aflwColors,
} from '@the-game/components'
import { mergeDeepRight } from 'ramda'

/**
 * @description Recursively merges original theme colour values with overriden values
 * @param originalTheme Original theme object
 * @param [sport] Colour scheme for a specific sport
 * @param [overrides] Additional colour value overrides
 * @returns Merged theme
 */
export function mergeThemeColorValues(
    originalTheme: Theme,
    sport?: SportName,
    overrides?: ThemeColorOverrideMap,
) {
    const sportTheme = sport && getSportTheme(sport)

    const themeOverrides =
        sportTheme && overrides
            ? mergeDeepRight(sportTheme, overrides)
            : sportTheme
            ? sportTheme
            : overrides

    if (!themeOverrides) {
        return originalTheme
    }

    const newTheme = mergeDeepRight(originalTheme, { colors: themeOverrides })

    return newTheme
}

function getSportTheme(sport: SportName) {
    switch (sport) {
        case 'Cricket':
            return cricketColors
        case 'WAFL':
            return waflColors
        case 'AFLW':
            return aflwColors
        default:
            return
    }
}
